<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showpaye"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span>Lettrage</span>
            <v-spacer></v-spacer>
            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            >
            </v-text-field>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <span>Montant Restant a lettrer : </span>

                  <span class="green--text">
                    {{
                      numberWithSpace(rest ? rest.toFixed(2) : "0.00") + " DA"
                    }}
                  </span>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-progress-linear
                    :value="(rest / paiement.montant) * 100"
                    color="light-green darken-2"
                    height="25"
                    rounded
                  >
                    <template v-slot:default>
                      <strong
                        >{{
                          ((rest / paiement.montant) * 100).toFixed(2)
                        }}%</strong
                      >
                    </template>
                  </v-progress-linear>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-data-table
                    v-model="doc_select"
                    :headers="
                      paiement.facture == '0' ? mvmheaders : factheaders
                    "
                    :items="docs_list"
                    :item-key="'id'"
                    class="elevation-1"
                    :search="search"
                    ref="refName"
                    :key="klist"
                    :loading="data_load"
                    @current-items="getFiltered"
                  >
                    <template v-slot:item="{ item, isSelected, select, index }">
                      <tr
                        :class="isSelected ? 'green' : ''"
                        @click="toggle(isSelected, select, item)"
                      >
                        <td>
                          <v-layout>
                            {{ index + 1 }}
                          </v-layout>
                        </td>
                        <td class="d-flex align-center">
                          {{
                            paiement.facture == "0" ? item.nobon : item.nofact
                          }}
                          <v-icon class="px-1" color="black" v-if="isSelected"
                            >mdi-check</v-icon
                          >
                        </td>
                        <td align="right">
                          {{
                            paiement.facture == "0"
                              ? datefr(item.opr_date)
                              : datefr(item.fact_date)
                          }}
                        </td>
                        <td align="right">
                          {{ datefr(item.date_limite) }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item ? item.mont_ttc.toFixed(2) : "0.00"
                            )
                          }}
                        </td>
                        <td align="right" v-if="paiement.facture == '0'">
                          {{
                            numberWithSpace(
                              item
                                ? (item.mont_ret ? item.mont_ret : 0).toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right" v-if="paiement.facture == '0'">
                          {{
                            numberWithSpace(
                              item
                                ? (item.mont_fact ? item.mont_fact : 0).toFixed(
                                    2
                                  )
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          {{
                            numberWithSpace(
                              item ? item.ttc_paye.toFixed(2) : "0.00"
                            )
                          }}
                        </td>
                        <td
                          align="right"
                          :class="isSelected ? '' : 'green--text'"
                        >
                          {{
                            numberWithSpace(
                              item.ttc_reste
                                ? item.ttc_reste.toFixed(2)
                                : "0.00"
                            )
                          }}
                        </td>
                        <td align="right">
                          <v-progress-linear
                            :value="
                              (item.ttc_paye /
                                (item.mont_ttc -
                                  (item.mont_ret ? item.mont_ret : 0) -
                                  (item.mont_fact ? item.mont_fact : 0))) *
                              100
                            "
                            color="light-blue darken-2"
                            height="25"
                            rounded
                          >
                            <template v-slot:default>
                              <strong
                                >{{
                                  (
                                    (item.ttc_paye /
                                      (item.mont_ttc -
                                        (item.mont_ret ? item.mont_ret : 0) -
                                        (item.mont_fact
                                          ? item.mont_fact
                                          : 0))) *
                                    100
                                  ).toFixed(2)
                                }}%</strong
                              >
                            </template>
                          </v-progress-linear>
                        </td>
                      </tr>
                    </template>
                    <template slot="body.append" v-if="docs_list.length > 0">
                      <tr>
                        <th class="title">Total</th>
                        <th class="title" colspan="3">
                          {{
                            search
                              ? filtereditems.length
                              : docs_list
                              ? docs_list.length
                              : 0
                          }}
                        </th>

                        <th class="title text-right">
                          {{ numberWithSpace(sumField("mont_ttc").toFixed(2)) }}
                        </th>
                        <th
                          class="title text-right"
                          v-if="paiement.facture == '0'"
                        >
                          {{ numberWithSpace(sumField("mont_ret").toFixed(2)) }}
                        </th>
                        <th
                          class="title text-right"
                          v-if="paiement.facture == '0'"
                        >
                          {{
                            numberWithSpace(sumField("mont_fact").toFixed(2))
                          }}
                        </th>
                        <th class="title text-right">
                          {{ numberWithSpace(sumField("ttc_paye").toFixed(2)) }}
                        </th>
                        <th class="title green--text text-right">
                          {{
                            numberWithSpace(sumField("ttc_reste").toFixed(2))
                          }}
                        </th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>

              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!changed"
                v-if="doc_select.length > 0"
              >
                Enregistrer
              </v-btn>
            </v-container>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import INSERT_PAYE_DETAIL from "../graphql/Paiement/INSERT_PAYE_DETAIL.gql";
import ACTUAL_MVMS from "../graphql/Paiement/ACTUAL_MVMS.gql";
import ACTUAL_FACTS from "../graphql/Paiement/ACTUAL_FACTS.gql";
import { myBL } from "print/data.js";
export default {
  components: {},
  name: "payedetailform",
  props: {
    items: Array,
    paiement: Object,
    showForm: Boolean,
    paye_type: { type: Number, default: 1 },
  },
  data: () => ({
    progress: false,
    valid: true,
    changed: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    search: null,
    doc_select: [],
    filtereditems: [],
    facts_list: [],
    mvms_list: [],
    klist: 200,
    data_load: false,
    factheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Facture No",
        value: "nofact",
        selected: true,
      },
      {
        text: "Date Facture",
        value: "fact_date",
        slot: "date",
        align: "end",
        selected: true,
      },
      {
        text: "Echeance",
        value: "date_limite",
        slot: "date",
        align: "end",
        selected: true,
      },
      {
        text: "T.T.C",
        value: "mont_ttc",
        selected: true,
        align: "end",
      },

      {
        text: "Total Payé",
        value: "ttc_paye",
        selected: true,
        align: "end",
      },
      {
        text: "Reste a Payer",
        value: "ttc_reste",
        selected: true,
        align: "end",
      },
      {
        text: "% Reglé",
        value: "ttc_reste",
        selected: true,
        align: "end",
        width: "100",
      },
    ],

    mvmheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "BE No",
        value: "nobon",
        selected: true,
      },
      {
        text: "Date BE",
        value: "opr_date",
        slot: "date",
        align: "end",
        selected: true,
      },
      {
        text: "Due Date",
        value: "date_limite",
        align: "end",
        selected: true,
      },
      {
        text: "T.T.C",
        value: "mont_ttc",
        selected: true,
        align: "end",
      },
      {
        text: "Ret.",
        value: "mont_ret",
        selected: true,
        align: "end",
      },
      {
        text: "Ret.",
        value: "mont_fact",
        selected: true,
        align: "end",
      },
      {
        text: "Total Payé",
        value: "ttc_paye",
        selected: true,
        align: "end",
      },
      {
        text: "Reste a Payer",
        value: "ttc_reste",
        selected: true,
        align: "end",
      },
      {
        text: "% Reglé",
        value: "ttc_reste",
        selected: true,
        align: "end",
        width: "100",
      },
    ],
  }),

  computed: {
    docs_list() {
      let list = [];
      if (this.paiement.facture == "0") {
        list = this.mvms_list;
      } else {
        if (this.paiement.modepaiement == "4")
          list = this.facts_list.filter((elm) => elm.modepaiement == "4");
        else list = this.facts_list.filter((elm) => elm.modepaiement != "4");
      }
      return list;
    },

    showpaye() {
      return this.showForm;
    },

    rest() {
      let r =
        this.paiement.reste -
        this.doc_select.reduce((a, b) => a + (b["montant"] || 0), 0);

      return r >= 0 ? r : 0;
    },
  },
  watch: {},

  async created() {
    //si noir && autorisations
    if (
      myBL == "n" &&
      this.paiement.facture == 0 &&
      this.paiement.tier_id &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    ) {
      let v = {
        TypeScope: this.paye_type,
        where: {
          AND: [
            { column: "TIER_ID", value: this.paiement.tier_id },
            { column: "STATUT_ID", operator: "GTE", value: 11 },
            { column: "TTC_RESTE", operator: "GT", value: 0 },
          ],
        },
      };
      this.data_load = true;
      let r = await this.requette(ACTUAL_MVMS, v, "no-cache");
      this.data_load = false;
      if (r) {
        this.mvms_list = r.allmvm;
        this.klist++;
      }
    }
    if (
      this.paiement.facture == 1 &&
      this.paiement.tier_id &&
      this.paye_type == 3
    ) {
      let v = {
        TypeScope: this.paye_type,
        where: {
          AND: [
            { column: "TIER_ID", value: this.paiement.tier_id },
            { column: "STATUT_ID", value: 14 },
            { column: "TTC_RESTE", operator: "GT", value: 0 },
          ],
        },
      };
      this.data_load = true;
      let r = await this.requette(ACTUAL_FACTS, v, "no-cache");
      this.data_load = false;
      if (r) {
        this.facts_list = r.allfact;
        this.klist++;
      }
    }
    this.mvms_list.forEach((element) => {
      element.old_paye = element.ttc_paye;
    });
    this.facts_list.forEach((element) => {
      element.old_paye = element.ttc_paye;
    });
  },
  mounted() {},

  methods: {
    sumField(key) {
      let list = this.search ? this.filtereditems : this.docs_list;
      // sum data in give key (property)
      return list ? list.reduce((a, b) => a + (b[key] || 0), 0) : 0;
    },
    getFiltered() {
      if (this.search)
        this.filtereditems = this.$refs.refName.$children[0].filteredItems;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    toggle(isSelected, select, item) {
      this.changed = true;
      if (isSelected) {
        select(!isSelected);
        item.ttc_paye = item.old_paye;
        item.ttc_reste =
          item.mont_ttc -
          (item.mont_ret ? item.mont_ret : 0) -
          (item.mont_fact ? item.mont_fact : 0) -
          item.old_paye;
        item.montant = 0;
      } else if (this.rest > 0) {
        item.montant = item.ttc_reste <= this.rest ? item.ttc_reste : this.rest;
        item.ttc_paye = item.old_paye + item.montant;
        item.ttc_reste =
          item.mont_ttc -
          (item.mont_ret ? item.mont_ret : 0) -
          (item.mont_fact ? item.mont_fact : 0) -
          item.ttc_paye;
        select(!isSelected);
      }
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    close() {
      this.$emit("close");
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async save() {
      let list = [];
      this.docs_list
        .filter((elm) => elm.montant > 0)
        .forEach((element) => {
          if (element.montant > 0)
            if (this.paiement.facture == 0)
              list.push({
                paye_id: this.paiement.id,
                mvm_id: element.id,
                montant: parseFloat(element.montant.toFixed(2)),
              });
            else
              list.push({
                paye_id: this.paiement.id,
                fact_id: this.paye_type == 3 ? element.id : null,
                fact_av_id: this.paye_type == 1 ? element.id : null,
                montant: parseFloat(element.montant.toFixed(2)),
              });
        });
      let v = {
        list: list,
      };
      let r = await this.maj(INSERT_PAYE_DETAIL, v, true);
      if (r) {
        this.changed = false;
        this.close();
      }
    },
  },
};
</script>
